<template>
  <div class="container">
    <div class="row justify-content-center align-items-center">
      <div class="d-none d-md-block col">
        <div
          class="swiper-button-prev home-swiper-button-prev"
          :class="`${elementName}-prev`"
        ></div>
      </div>
      <div class="col-md-11">
        <swiper
          :ref="elementName"
          :breakpoints="breakpoints"
          :navigation="{
            nextEl: `.${elementName}-next`,
            prevEl: `.${elementName}-prev`,
          }"
          :loop="true"
        >
          <slot />
        </swiper>
        <div class="d-md-none swiper-button-prev" :class="`${elementName}-prev`"></div>
        <div
          class="d-md-none swiper-button-next swiper-button-white"
          :class="`${elementName}-next`"
        ></div>
      </div>
      <div class="d-none d-md-block col">
        <div
          class="swiper-button-next swiper-button-white home-swiper-button-next"
          :class="`${elementName}-next`"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HomeArrowSwipers",
  props: {
    elementName: {
      type: String,
      required: true,
    },
    breakpoints: {
      type: Object,
      default: () => ({
        320: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        800: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      }),
    },
  },
};
</script>
